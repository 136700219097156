import { createFeature, createReducer, on } from '@ngrx/store';
import { Carrier } from 'src/app/shared/models';
import { CarrierActions } from './carrier.actions';

export const carrierFeatureKey = 'carrier';

export interface CarrierState {
  carriers: Carrier[];
  selectedCarrier: Carrier | null;
  loading: boolean;
  error: any;
}

export const initialState: CarrierState = {
  carriers: [],
  selectedCarrier: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CarrierActions.loadCarriers,
    (state): CarrierState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CarrierActions.loadCarriersSuccess,
    (state, { carriers }): CarrierState => ({
      ...state,
      carriers,
      loading: false,
      error: null,
    })
  ),
  on(
    CarrierActions.loadCarriersFailure,
    (state, { error }): CarrierState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CarrierActions.loadCarrier,
    (state): CarrierState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CarrierActions.loadCarrierSuccess,
    (state, { carrier }): CarrierState => ({
      ...state,
      carriers: [carrier],
      selectedCarrier: carrier,
      loading: false,
      error: null,
    })
  ),
  on(
    CarrierActions.loadCarrierFailure,
    (state, { error }): CarrierState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CarrierActions.addCarrier,
    (state): CarrierState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CarrierActions.addCarrierSuccess,
    (state, { carrier }): CarrierState => ({
      ...state,

      carriers: [...state.carriers, carrier],

      loading: false,

      error: null,
    })
  ),
  on(
    CarrierActions.addCarrierFailure,
    (state, { error }): CarrierState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CarrierActions.removeCarrier,
    (state): CarrierState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CarrierActions.removeCarrierSuccess,
    (state, { carrierId }): CarrierState => ({
      ...state,
      carriers: state.carriers.filter(carrier => carrier.id !== carrierId),
      loading: false,
      error: null,
    })
  ),
  on(
    CarrierActions.removeCarrierFailure,
    (state, { error }): CarrierState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CarrierActions.updateCarrier,
    (state): CarrierState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CarrierActions.updateCarrierSuccess,
    (state, { carrier }): CarrierState => ({
      ...state,
      carriers: state.carriers.map(item =>
        // item.id === carrier.id ? carrier : item
        item.id === carrier.id ? { ...item, ...carrier } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    CarrierActions.updateCarrierFailure,
    (state, { error }): CarrierState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const carrierFeature = createFeature({
  name: carrierFeatureKey,
  reducer,
});
